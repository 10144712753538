import request from '@/utils/axios'

export function selectList () {
  return request({
    url: `/api/form/select_list`,
    method: 'get',
  })
}

export function feedbackList (params) {
  return request({
    url: `/api/console/form/feedback`,
    method: 'get',
    params: params
  })
}

export function feedbackDetail (id) {
  return request({
    url: `/api/console/form/feedback/${id}`,
    method: 'get',
  })
}

export function feedbackChangeStatus (id, status) {
  return request({
    url: `/api/console/form/feedback/${id}`,
    method: 'get',
    params: {
      state: status
    }
  })
}